<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-toolbar-title >
            <v-breadcrumbs :items="breadItem">
            <v-icon slot="divider">mdi-forward</v-icon>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom"   v-model="form.tem_label" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Code"  v-model="form.tem_code" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.tem_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
        <div class="text-right mt-3">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/themes/">Retour</v-btn>
        </div>
    </v-card>

    <v-data-table v-if="form.tem_id" class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="headers" :items="items" item-key="obt_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page="pagination.rowsPerPage" :footer-props="footer">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Formulaires</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn outlined rounded small :color="'green'" :href="'/#/themes/' + form.tem_id + '/objecttype/0'"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.obt_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.obt_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.obt_id`]="{ item }">
            <v-btn icon color="orange" :href="'/#/themes/' + item.obt_tem_id + '/objecttype/' + item.obt_id">
                <v-icon left color="orange">mdi-pencil</v-icon>
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'theme-edit',
    props: ['toast'],
    data: function () {
        return {
            breadItem: [
                { text: 'Modèle', disabled: false, href: '/#/themes/' },
            ],
            search: '',
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            saveProgress: false,         // Enregistrement en cours...
            form:{
              tem_id: 0, tem_sta_id: 'GEN01', tem_label: '', tem_code: '', 
              tem_created_at: null, tem_updated_at: null
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
            headers: [
            { text: 'Nom',     align: 'left', value: 'obt_label'},
            { text: 'Code',    align: 'left', value: 'obt_code'},
            { text: 'Type',    align: 'right', value: 'obt_sta_id' },
            { text: 'Action',  align: 'right', value: 'obt_id' }
            ],
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.tem_id ) > 0 && this.$route.params.tem_id !== undefined ){
            this.form.tem_id = this.$route.params.tem_id
            this.breadItem.push( { text: '#' + this.form.tem_id, disabled: false } ),

            this.$http.get( '/themes/' + this.$route.params.tem_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
            this.$http.get( '/objecttypes/?per_page=false&obt_tem_id=' + this.form.tem_id )
            .then( (response) => {
                this.items = response.data.data
            })
        }
    },
    methods: {
        async getTheme(){

        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.form.tem_id > 0 ){
                this.$http.put( '/themes/' + this.form.tem_id, this.form )
                .then( () => {
                    this.saveProgress = false
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                }).catch( (error) => {
                    this.saveProgress = false
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/themes/', this.form ).then( (response) => {
                    for( let prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                    this.saveProgress = false
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: { }
}
</script>