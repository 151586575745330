<template>
<v-container fluid class="pa-0">
<v-card>
    <v-toolbar class="elevation-1" color="primary" height="48px">
        <v-toolbar-title >Element</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Libellé" v-model="lsdObj.lsd_label" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Code" v-model="lsdObj.lsd_code" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="lsdObj.lsd_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs12 class="pa-2 purple--text">
            Lisaison formulaire
        </v-flex>
        <v-flex xs6 class="pr-2 pl-2">
            <v-select color="purple"
                :items="lists" item-value="lst_id" item-text="lst_label"
                v-model="listLink.lst_id" label="Liste" >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pr-2 pl-2" v-if="listLink.lst_id && ( listLink.lst_id !== lsdObj.lsd_lst_id )">
            <v-select 
                :items="listsData[ listLink.lst_id ]" item-value="lsd_id" item-text="lsd_label"
                v-model="lsdObj.lsd_lsd_id_link" label="valeur" >
            </v-select>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-card-actions>
        <v-btn v-if="lsdObj.lsd_id" class="ma-0" color="error" @click.native="deleteItem()" :disabled="saveProgress">
            <v-icon left>mdi-trash-can</v-icon>Supprimer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :loading="saveProgress" @click.native="saveItem()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('lsdModal', 0, false)">Retour</v-btn>
    </v-card-actions>
</v-card> 
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'cardtemplate-edit',
    props: ['toast', 'lsd_id', 'switchModal'],
    data: function () {
      return {
        saveProgress: false,         // Enregistrement en cours...
        locDateFormat: dateLocale, // Format de date
        state: {
            lst: []
        },
        lsdObj: {
            lsd_id: 0, lsd_lst_id: 0, lsd_sta_id: 'GEN01', lsd_code: '', lsd_label: '', lsd_lsd_id_link: 0,
            lsd_order: 0, lsd_usr_id_creat: null
        },
        lsdObjClone:'',
        lists : [],
        listsData: {},
        listLink:  {
            lst_id: 0,
            lsd_id: 0,
        }
      }
    },
    mounted: function (){
        this.lists     = this.getLists()
        this.listsData = this.getListsDataByLstId()

        if( this.$route.params.lst_id !== undefined && this.$route.params.lst_id > 0 ){
            this.lsdObj.lsd_lst_id = this.$route.params.lst_id
        } else {
            this.$router.push({ path: '/lists/' })
        }
        this.lsdObjClone = JSON.stringify( this.lsdObj )
        if( this.lsd_id > 0 ){
            this.getItem()
        }
    },

    methods: {
        getItem(){
            this.$http.get( '/listdatas/' + this.lsd_id )
            .then( (response) => {
                for( let prop in response.data.data ){
                    this.lsdObj[prop] = response.data.data[prop] //if( this.lsdObj[prop] !== undefined ){}
                }
                let listData    = cacheGetters.getListsData()
                let listDataObj = listData.find(obj => obj.lsd_id === this.lsdObj.lsd_lsd_id_link)
                if( listDataObj !== undefined ){
                    this.listLink.lst_id = listDataObj.lsd_lst_id
                }
            })
        },
        saveItem(){
            if( this.lsdObj.lsd_id > 0 ){
                this.$http.put( '/listdatas/' + this.lsdObj.lsd_id, this.lsdObj )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                    this.switchModal('lsdModal', 0, true)
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.switchModal('lsdModal', 0, true)
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/listdatas', this.lsdObj ).then( () => {
                    //this.ctp_id = response.data.ctp_id
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                    this.switchModal('lsdModal', 0, true)
                })
            }
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/listdatas/' + this.lsdObj.lsd_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Item supprimée !' })
                        this.switchModal('lsdModal', 0, true)
                    })
                }
            })
        },
        cleanObj(){
            var ctpClone = JSON.parse( this.lsdObjClone )
            var prop
            for( prop in ctpClone ){
                if( this.lsdObj[prop] !== undefined ){
                    this.lsdObj[prop] = ctpClone[prop]
                }
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getLists:      cacheGetters.getLists,
        getListsDataByLstId: cacheGetters.getListsDataByLstId,
    },
    components: { }
}
</script>