<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des états</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" to="/states/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.usr_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.sta_id }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text"></span>
            </td>
            <td class="text-xs-left">{{ item.sta_label }}</td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ item.sta_entity }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip v-if="item.sta_visibility" :active="true" outlined small label :color="item.sta_color" >
                    {{ (item.sta_visibility) ? 'Visible' : 'Désactivé' }}
                </v-chip>
            </td>
            <td class="text-right">
                <span class="caption text--disabled font-italic">Créé le </span>
                <span class="caption text--primary font-italic">{{ locDateFormat.toLocale(item.sta_created_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/states/' + item.sta_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'states-page',
    props: ['toast'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'sta_id'},
            { text: 'Libellé', align: 'left', value: 'sta_label'},
            { text: 'Type', value: 'sta_entity' },
            { text: 'Statut', value: 'sta_visibility' },
            { text: 'Modification', align: 'right', value: 'sta_updated_at' }
            ],
            items: [

            ],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('state') || ''
        this.getItems()

    },
    methods: {
        getItems(){
            this.$http.get( '/states?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('state', this.search)
        },
    },
    components: {
        
    }
}
</script>