<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" color="barrinfo">
        <v-toolbar-title >Modèles</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/themes/0" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Nouveau</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo single-line hide-details clearable v-model="search" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.tem_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.tem_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.tem_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/themes/' + item.tem_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune données pour ce tableau
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'theme-page',
    props: ['toast'],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'tem_label'},
            { text: 'Code', align: 'left', value: 'tem_code'},
            { text: 'Statut', align: 'left', value: 'tem_sta_id'},
            { text: 'Action', align: 'right', value: 'tem_id'},
            ],
            items: [],
            temId: 0,
            locDateFormat: dateLocale, // Format de date
            themeModal: false
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
    },
    mounted: function (){
        this.getThemeList()
    },
    methods: {
        getThemeList(){
            this.$http.get( '/themes/?per_page=false' )
            .then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
            })
        },
        goRoute(id){
            this.$router.push({ path: '/themes/' + id + '/objtype' })
        },
        switchModal(name, mode, refresh){
            name    = name || 'themeModal'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){
                this.getThemeList()
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    },
}
</script>

<style lang="css">

</style>