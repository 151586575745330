<template>
<v-container fluid :class="{ 'blue': dragover }" 
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false">
    <v-toolbar class="elevation-0" height="48px">
        <v-toolbar-title >Liste des fichiers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="enableAdd && isAuthorized( 'document', 'edit' )" 
            outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openDocument(0)">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-toolbar>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" :loading="loading.getDocument"> 
        <template v-slot:[`item.doc_created_at`]="{ item }">
            <v-chip label outlined >{{ $date(item.doc_created_at).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.doc_name`]="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 400px;">
            {{ item.doc_name }}
            </span>
        </template>
        <template v-slot:[`item.doc_id`]="{ item }">
            <v-icon v-if="( table_id == 'eve' && item.doc_mimetype == 'image/jpeg')" left color='primary' title="Associer l'image" @click="attachDocument(item.doc_id)">
                mdi-paperclip
            </v-icon>
            <v-icon left color='primary' title="Télécharger" @click="downloadDocument(item.doc_id)">
                mdi-file-download
            </v-icon>
            <v-icon left color='orange' title="Editer" v-if="isAuthorized( 'document', 'edit' )" @click="editDocument(item.doc_id)">
                mdi-pencil
            </v-icon>
            <v-icon left color='red' title="Supprimer" v-if="isAuthorized( 'document', 'delete' )" @click="deleteDocument(item.doc_id)">
                mdi-delete-forever
            </v-icon>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
        <template slot="no-data">
        <v-alert :value="( !loading.getDocument )" color="error" icon="mdi-alert">
            Aucune fichier, ajoutez un nouveau fichier
        </v-alert>
        </template>
    </v-data-table>

    <v-dialog v-model="modalLayer" max-width="900" >
        <!-- <tab-layer v-if="modalLayer" :doc_id="doc_id" :switchModal="switchModal"></tab-layer> -->
    </v-dialog>
    <v-dialog v-model="modalDocument" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalDocument', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <DocumentForm class="elevation-2" 
                v-if="modalDocument" :switchModal="switchModal"
                :xxxId="doc_xxx_id" :docId="docInfo.doc_id" :docTable="table_id" :docType="'DOC'" :withFile="uploadedFiles" />
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import DocumentForm from '@/components/documents/DocumentForm.vue'
import { usrGetters }   from "@/store/user.module"

export default {
    name: 'document-list',
    components: {
        DocumentForm
    },
    props: ['table_id', 'doc_xxx_id', 'routeState'],
    data: function () {
      return {
            loading: { uploadBtn: false, getDocument: false },         // Enregistrement en cours...
            dragover: false,
            uploadedFiles: undefined,
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'doc_name'},
            { text: 'Date de création', value: 'doc_created_at', align: 'left'},
            { text: 'Télécharger', value: 'doc_id', align: 'right' }
            ],
            items: [],
            enableAdd: true,
            addItem: {
                doc_id: 0 , doc_xxx_id: this.doc_xxx_id, doc_table_id: 0, doc_sta_id: 'GEN01',
                doc_filename: '', doc_name: '', doc_description: '',
                _method: 'POST', _path: 'document'
            },
            modalLayer : false,
            docInfo: { doc_id: 0, doc_sta_id : 'DOC01', doc_table_id: this.table_id, doc_xxx_id  : this.doc_xxx_id },
            modalDocument: false,
            isAuthorized: usrGetters.getScopeRight
      }
    },
    mounted: function (){
        if( this.doc_xxx_id !== undefined ){
            this.getDocument()
        }
    },
    methods: {
        getDocument(){
            //this.items = []
            this.loading.getDocument = true
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&doc_xxx_id=' +  this.doc_xxx_id + '&doc_table_id=' + this.table_id
            this.$http.get( '/documents/' + searchCriteria ).then( (response) => {
                if( response.data.meta.total !== undefined && response.data.meta.total !== '0' ){
                    this.pagination.totalItems  = parseInt(response.data.meta.total)
                } else {
                    this.pagination.totalItems = 1
                }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.items = response.data.data
                }
                this.loading.getDocument = false
            })
        },
        addLineFile() {
            this.enableAdd = false
            this.items.push({
                doc_id      : 0,
                doc_filename: '',
                doc_name    : '',
                doc_description: ''
            })
        },
        openDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        editDocument(id){
            this.docInfo.doc_id = id
            this.switchModal('modalDocument', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalLayer', 1)
            }
        },
        deleteDocument( docid ){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de ce document', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( 'documents/' +  docid).then( () => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Document supprimée !' })
                        this.getDocument()
                    })
                }
            })
        },
        downloadDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        attachDocument(doc_id){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/events/' + this.doc_xxx_id + '/picture', { eve_doc_id_image: doc_id } ).then( () => {
                    this.$root.$toast({ color: 'success', text: 'Image attribuée' })
                    this.$emit('linkDoc', doc_id)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalDocument'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.uploadedFiles = undefined
                //this.doc_xxx_id = 0
            }
            if( refresh ){
                this.getDocument()
            }
        },
        onDrop(e) {
            this.dragover = false;
            if (e.dataTransfer.files.length > 1) {
                this.$root.$toast({ color: 'error', text: 'Un seul fichier à la fois.' })
            } else {
                e.dataTransfer.files.forEach(element =>
                    this.uploadedFiles = element
                )
                this.openDocument(0)
            }
        }
    }
}
</script>