<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-toolbar-title >
            <v-breadcrumbs :items="breadItem">
            <v-icon slot="divider">mdi-forward</v-icon>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2"><v-text-field label="Nom"  v-model="form.obt_label" ></v-text-field></v-flex>
        <v-flex xs3 class="pa-2"><v-text-field label="Code" v-model="form.obt_code" ></v-text-field></v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.obt_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
        <div class="text-right mt-3">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/themes/">Retour</v-btn>
        </div>
    </v-card>

    <v-data-table v-if="form.obt_id" class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="headers" :items="items" item-key="ctp_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page="pagination.rowsPerPage" :footer-props="footer">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Champs</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn outlined rounded small :color="'green'" @click="openCardTemplate(0)"> <!-- :disabled="!routeState.edit" -->
                Ajouter &nbsp;<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            <v-btn v-show="fieldOrdered" outlined rounded small :color="'orange'" class="ml-2" @click="saveFieldOrder()"> <!-- :disabled="!routeState.edit" -->
                Enregistrer l'ordre &nbsp;<v-icon>mdi-content-save-all</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:body="props">
            <draggable v-model="items" tag="tbody" @end="onReorder">
                <tr v-for="(item, index) in props.items" :key="index" style="cursor:pointer;"> <!-- @click="openSite(item.trs_id)" -->
                    <td class="text-left"> {{ item.ctp_label }} </td>
                    <td class="text-left"><v-chip small outlined label :color="getCacheState('GEN', item.ctp_sta_id).sta_color">{{ getCacheState('GEN', item.ctp_sta_id).sta_label }}</v-chip></td>
                    <td class="text-left"><v-chip small outlined label :color="getCacheType('SHA', item.ctp_typ_id).typ_color">{{ getCacheType('SHA', item.ctp_typ_id).typ_name }}</v-chip></td>
                    <td> <v-chip small outlined label>{{ item.ctp_data_type }}</v-chip></td>
                    <td class="text-right">
                        <v-chip small outlined label>{{ item.ctp_code }}</v-chip>
                    </td>
                    <td class="text-right">
                        <v-chip small outlined label :color="getCacheType('SHA', item.ctp_typ_id).typ_color">{{ getCacheType('CTP', item.ctp_typ_id_section).typ_name }}</v-chip>
                    </td>
                    <td class="text-center"> {{ index + 1 }} </td>
                    <td class="text-right">
                        <v-btn icon color="orange" @click="openCardTemplate(item.ctp_id)">
                            <v-icon left color="orange">mdi-pencil</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </draggable>
        </template>
    </v-data-table>

    <!-- Modal de creation -->
    <div class="text-xs-center">
        <v-dialog v-model="ctpModal" width="500">
            <component v-if="ctpModal" :toast="toast" :is="'cardtpl-edit'" :ctp_id="ctp_id" :switchModal="switchModal" ></component>
        </v-dialog>
    </div>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import editCardtemplate  from '../components/Edit_cardtemplate.vue'
import draggable from "vuedraggable"

export default {
    name: 'objtype-page',
    props: ['toast'],
    data: function () {
        return {
            breadItem: [
                { text: 'Modèle', disabled: false, href: '/#/themes/' },
            ],
            search: '',
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: -1, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            saveProgress: false,         // Enregistrement en cours...
            fieldOrdered: false,
            form:{
                obt_id: 0, obt_sta_id: 'GEN01', obt_tem_id: 0, obt_label: '', obt_code: '', 
                obt_usr_id_creat: null, obt_created_at: null,
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
            headers: [
                { text: 'Champ',    align: 'left',  value: 'ctp_label'},
                { text: 'Statut',   align: 'left',  value: 'ctp_sta_id'},
                { text: 'Type',     align: 'left',  value: 'ctp_typ_id'},
                { text: 'Donnée',   align: 'right', value: 'ctp_data_type' },
                { text: 'Code',     align: 'right', value: 'ctp_code' },
                { text: 'Section',  align: 'right', value: 'ctp_typ_id_section' },
                { text: 'Ordre',    align: 'right', value: 'ctp_pos_row' },
                { text: 'Action',   align: 'right', value: 'ctp_id' },
            ],
            ctpModal: false,
            ctp_id: 0
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.obt_id ) > 0 && this.$route.params.obt_id !== undefined ){
            this.form.obt_id = this.$route.params.obt_id
            this.form.obt_tem_id = this.$route.params.tem_id
            this.breadItem.push( { text: '#' + this.form.obt_tem_id, disabled: false, href: '/#/themes/' + this.form.obt_tem_id } )
            this.breadItem.push( { text: 'Formulaire', disabled: false } )
            this.getItems()
            this.getCardTemplate()
        } else if( parseInt( this.$route.params.tem_id ) > 0 && this.$route.params.tem_id !== undefined ){
            this.form.obt_tem_id = this.$route.params.tem_id
            this.breadItem.push( { text: '#' + this.form.obt_tem_id, disabled: false, href: '/#/themes/' + this.form.obt_tem_id } )
        }
    },
    methods: {
        getCardTemplate(){
            this.$http.get( '/cardtemplates/?per_page=false&ctp_obt_id=' + this.form.obt_id + '&order_by=ctp_pos_row&order_direction=asc' )
            .then( (response) => {
                this.items = response.data.data
            })
        },
        getItems(){
            this.$http.get( '/objecttypes/' + this.form.obt_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.form.obt_id > 0 ){
                this.$http.put( '/objecttypes/' + this.form.obt_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/objecttypes/', this.form ).then( (response) => {
                    for( let prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                    this.saveProgress = false
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/themes/' + this.form.obt_tem_id + '/objecttype/' + this.form.obt_id })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        onReorder(){
            this.fieldOrdered = true
        },
        saveFieldOrder(){
            this.saveProgress = true
            this.items.forEach( (element, index) => {
                element.ctp_pos_row = index + 1
            })
            this.$http.post( '/cardtemplates/order', { cardtemplates: this.items } )
            .then( () => {
                this.toast({ color: 'success', text: 'Organisation enregistrée !' })
                this.saveProgress = false
                this.fieldOrdered = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
                this.fieldOrdered = false
            })
        },
        openCardTemplate(id){
            this.ctp_id   = id
            this.ctpModal = true
        },
        switchModal(name, mode, refresh){
            name    = name || 'ctpModal'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){
                this.getCardTemplate()
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'cardtpl-edit': editCardtemplate,
        draggable
    }
}
</script>