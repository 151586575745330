<template>
<v-app id="logout" light>
  <v-layout style="margin-top:1em;"></v-layout>
</v-app>
</template>

<script>
export default {
    name: 'logout-component'
}
</script>