<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/users/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche {{item.usr_lastname}} {{item.usr_firstname}}</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field required :rules="[() => !!item.usr_lastName || 'Champ obligatoire', item.usr_lastName.length >= 3]" 
                label="Nom"    v-model="item.usr_lastName" ></v-text-field>
            <v-text-field required :rules="[() => !!item.usr_firstName || 'Champ obligatoire', item.usr_firstName.length >= 3]"
                label="Prénom" v-model="item.usr_firstName"></v-text-field>
            <v-text-field required :rules="[() => !!item.usr_username || 'Champ obligatoire', item.usr_username.length >= 3]"
                label="Login"  v-model="item.usr_username" autocomplete="username"></v-text-field>
            <v-text-field label="Mot de passe" type="password" v-model="item.usr_password" loading autocomplete="new-password">
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Tel" v-model="item.usr_phone"></v-text-field>
            <v-text-field label="Mobile" v-model="item.usr_mobile"></v-text-field>
            <v-text-field required :rules="[() => !!item.usr_email || 'Champ obligatoire', item.usr_email.length >= 3]"
                label="Courriel" v-model="item.usr_email"></v-text-field>
            <v-select :items="roleList" item-value="value" v-model="item.usr_role" label="Role" single-line >
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(item.usr_updated_at, 'fr') }}
            </v-chip><br />
            <v-btn outlined color="primmary" class="mt-3" @click="resetPassword()">
                <v-icon left>mdi-mail</v-icon>Envoyer le mail
            </v-btn>
            </v-container>
        </v-flex>
        <v-flex xs8 class="pa-2">
              <AutocompleteMulti :title="'Entitées'" 
            :listObject.sync="item.projects" :listParent.sync="projects" 
            :fields="{ 
                index: 'pro_id', text:'pro_label', autofocus: true, outlined: true,  dense: true,
                search:'pro_label', multiSelect:true, params:'' 
        }"></AutocompleteMulti>
        </v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.usr_uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveUser()" :disabled="disabledSave">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/users/">Retour</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '@/services/dateLocale';
import { cacheGetters } from "@/store/cache.module"
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'

export default {
    name: 'user-edit',
    props: ['toast'],
    components: { AutocompleteMulti },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            roleList: [
                //{'value': 'BASIC_USER',      'text': 'Utilisateur (aucun droit)' },
                //{'value': 'ADMIN_USER',      'text': 'Administrateur' },
                {'value': 'SUPER_USER',      'text': 'Superviseur' },
                //{'value': 'WORK_SUPERVISOR', 'text': 'Superviseur Atelier' },
                //{'value': 'TEAM_LEADER',     'text': 'Chef Atelier' },
                {'value': 'AGENT',           'text': 'Utilisateur interne' },
                {'value': 'EXTERN_USER',     'text': 'Utilisateur externe' },
                {'value': 'PARTNER_USER',    'text': 'Utilisateur partenaire' },
            ],
            item:{
                usr_id: 0, usr_uid:'', usr_username: '', usr_password: '', projects: [],
                usr_lastName: '', usr_firstName: '',
                usr_phone: '', usr_mobile: '', usr_email: '',
                usr_created_at: '2000-01-01', usr_updated_at: '',
                usr_isActive: 1, usr_sta_id_type: 'USR01', usr_role: 'BASIC_USER',
            },
            locDateFormat: dateLocale, // Format de date
            rules: {
                text : [v => v.length <= 25 || 'Au moins 3 charactères'],
            },
            projects: cacheGetters.getProjects()
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.usr_username.length > 2 && this.item.usr_email.length > 2 && this.item.usr_username.length > 2  ){
                return false
            } else {
                return true
            }
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.usr_id ) > 0 && this.$route.params.usr_id !== undefined ){
            this.$http.get( '/users/' + this.$route.params.usr_id + '/?include=project' ).then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.item[prop] !== undefined ){
                        this.item[prop] = response.data.data[prop] 
                    }
                }
                if( response.data.data?.project?.data ){
                    this.item['projects'] = response.data.data.project.data.map( (item) => {
                        return item.pro_id
                    })
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveUser () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.usr_id ) > 0 ){
                this.$http.put( '/users/' + this.$route.params.usr_id, this.item )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/users/', this.item )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        deleteUser(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.usr_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.usr_id).then( () => {
                        this.toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        resetPassword(){
            this.$http.put( '/users/' + this.$route.params.usr_id + '/resetpass', {} )
            .then( () => {
                this.toast({ color: 'success', text: 'Mail envoyé !' })
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })            
        }
    }
}
</script>