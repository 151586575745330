<template>
<v-container fluid class="pa-1">
    <v-toolbar flat>
        <v-toolbar-title>
            <v-alert class="mb-0" border="left" dense colored-border type="info">Outils</v-alert>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    <v-btn outlined rounded small color="green" @click="syncLists()"> <!-- :disabled="!routeState.edit" -->
        Ajouter<v-icon>mdi-playlist-edit</v-icon>
    </v-btn>
    </v-toolbar>
    <v-layout row class="pl-6 pr-3">
    <v-flex xs3 class="pa-2 purple--text">
        Lisaison avec la liste :
    </v-flex>
    <v-flex xs10 class="pa-2">
    <v-alert dismissible color="green" 
        border="left" elevation="2" colored-border :icon="(loading.sync) ? 'mdi-sync' : 'mdi-power-standby'" class="mb-1">
        Synchroniser les listes sur les fiches des arbres

    <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
            <v-select color="purple"
                :items="lists" item-value="ctp_id" item-text="ctp_label"
                v-model="listSource" label="Liste 1" >
            </v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
            <v-select color="purple"
                :items="lists" item-value="ctp_id" item-text="ctp_label"
                v-model="listDesti" label="Liste 2" >
            </v-select>
        </v-col>
    </v-row>
    <v-chip outlined color="green" v-show="processing.list">{{resultSync.table}}</v-chip>
    <span v-show="processing.list"><br />
    Source: {{resultSync.source}} | Synchro: {{resultSync.sync}} | Insert: {{resultSync.insert}}
    </span>
    </v-alert>
    </v-flex>
    <v-flex xs2 class="pa-2">
        <v-btn class="mt-3" color="success" :loading="loading.sync" @click.native="syncLists()" :disabled="loading.sync">
            Demarrer
        </v-btn>
    </v-flex>
</v-layout>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'tabtools-edit',
    props: ['toast', 'pro_id'],
    components: { },
    data: function () {
      return {
            loading: { sync: false, uploadBtn: false, rebuildBtn: false, getVersion: false },         // Enregistrement en cours...
            processing: { list: false },
            locDateFormat: dateLocale,
            enableAdd: true,
            lists : [],
            listSource: 0,
            listDesti : 0,
            toolMessage: '',
            resultSync: { table: '' },
            prv_id: 0
      }
    },
    computed: {

    },
    beforeMount: function (){

    },
    mounted: function (){
        this.getLists()
        //let lists      = this.getLists()
        //this.lists     = lists.filter(word => word.lst_id !== this.lst_id)
    },
    methods: {
        syncLists(){
            this.$http.post( '/import/synch/listassoc/', {
                pro_id: this.pro_id, ctp_id_source: this.listSource, ctp_id_dest: this.listDesti 
            }).then( (response) => {
                this.resultSync = response.data.data['list_synclist']
                this.processing.list = true
            })
        },
        getLists(){
            this.$http.get( '/cardtemplates/?per_page=false&ctp_data_type=LIST&order_by=ctp_pos_row&order_direction=asc' )
            .then( (response) => {
                this.lists = response.data.data
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        //getLists:      cacheGetters.getLists,
        //getListsDataByLstId: cacheGetters.getListsDataByLstId,
    },
}
</script>