<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/equipments/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Materiel</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.spe_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.spe_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Libellé" v-model="objEquipment.equ_name"  ></v-text-field> <!-- v-model="form.equ_name" -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Modele / serie"  v-model="objEquipment.equ_serial" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('EQU')" v-model="objEquipment.equ_typ_id" label="Type" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="objEquipment.equ_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Informations complémentaires</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Description"  v-model="objEquipment.equ_customfields.description" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Immatriculation"  v-model="objEquipment.equ_customfields.immat1" ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mr-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
        <v-btn color="primmary" href="/#/equipments/">Retour</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }   from '../mixins/equ_obj.js'
//import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'equipment-edit',
    props: ['toast'],
    mixins: [ equObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            scanLabel: 'cliquez pour scanner',
            loading: { getItem: true },
            typeSpe : cacheGetters.getTypes('EQU'),
            form:{ 
                equ_id : '', equ_uid : '', equ_sta_id : 'GEN01', equ_typ_id : 'TYP01', equ_name : '', equ_cat_id : 1, equ_customfields : {},
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.objEquipment.equ_name.length > 0 ){
                return true
            } else {
                return false
            }
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.equ_id ) > 0 && this.$route.params.equ_id !== undefined ){
            this.objEquipment.equ_id = this.$route.params.equ_id
            this.getEquipmentShow()
        }
    },
    methods: {
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress    = true
            if( parseInt( this.$route.params.equ_id ) > 0 ){
                this.putEquipmentStore().then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipments' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.postEquipmentStore().then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipments' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.deleteEquipmentDestroy().then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/equipments' })
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        //'auto-multi': autoCompMulti
    }
}
</script>